.search-form-fields-container {
    background-color: #DCF1D7;
    font-size: 0.7rem;
    padding: 15px 5px 15px 5px;
}

.search-form-item {
    /*border: 1px solid red;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2px 2px 10px 2px;
}

.search-input-field {
    border-radius: 30px !important;
    font-size: 0.9rem;
    width: 180px;
}

div.search-input-field > div.select__control {
    border-radius: 30px;
}

div.basic-multi-select > div.select__control {
    border-radius: 30px;
}

.inline-fields-group {
    display: flex;
    flex-direction: row;
}
