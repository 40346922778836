@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

body, html {
    font-family: 'Nunito', sans-serif;
    color: #198527 !important;
    margin: 0;
    width: 100%;
}

a {
    color: #198527 !important;
}

    a:hover {
        color: #000 !important;
    }

h1, h2, h3, h4, h5, h6 {
    font-family: 'Merriweather', serif;
    color: #198527 !important;
}

.btn-valuta {
    border-radius: 30px;
    font-size: 0.75rem;
    background-color: #198527;
    border-color: #198527;
    color: white !important;
}

.btn-valuta-grey {
    border-radius: 30px;
    font-size: 0.75rem;
    background-color: #d5d5d5;
    border-color: #aeaeae;
    color: black !important;
}

hr {
    margin-top: 0rem;
    margin-bottom: 0.7rem;
}

.PlayerRangeCircleVeryLittle {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding-top: 1px;
}

.PlayerRangeCircleLittle {
    border-width: 1px;
    border-style: solid ;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 0.9rem;
    font-weight: 400;
}

.PlayerRectangleCircleLittle {
    border-radius: 3px;
    width: 60px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
}

.valuta-btn-hide {
    cursor: pointer;
}
.valuta-btn-hide:hover {
    color:#ccc;
}
