.sheet-subject-container, .sheet-footer-container {
    background-color: rgba(218, 234, 213, 0.85);
    border-radius: 5px;
    margin: 0.25rem;
    padding: 0.25rem;
}

.sheet-final-result-container {
    background-color: #ededed;
    border-radius: 5px;
    margin: 0.25rem;
    padding: 0.5rem;
}

.sheet-question-container {
    background-color: #f5f5f5;
    border-radius: 5px;
    margin: 0.25rem 0 0.25rem 0;
    padding: 0.25rem;
    padding-top: 0.6rem;
}

.sheet-subject-weight {
    color: rgba(0,0,0,0.2);
    font-weight: 800;
}

.sheet-evaluation-subject-rate {
    color: rgba(0,0,0,0.6);
    font-size: 0.6rem;
    font-weight: 600;
}

.sheet-question-text {
    font-size: 0.8rem;
}

.sheet-question-number {
    position: absolute;
    border: 3px solid #FFF;
    background-color: #9dd1a1;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-left: -18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 0.65rem;
    color: #29742d;
}

.mandatoryField {
    color: red;
}

.highlight-data-consulenza {
    border: 5px solid rgba(255,255,0,0.5);
}


.sheet-player-range-description {
    font-size: 0.75rem;
    margin-right: 5px;
    border-radius: 3px;
    color: white;
    font-weight: 600;
    padding: 2px 7px 2px 7px;
    border-width: 1px;
    border-style: solid;
}

.sheet-total-rate-percent {
    font-weight: 600;
}

.sheet-total-rate-percent-bar {
    border: 1px solid #a7baab;
    min-width: 130px;
    height: 25px !important;
    margin-right: 5px;
    color: black !important;
}

.bagde-criticita {
    font-size: 0.6rem;
    padding: 4px;
    border-width: 1px;
    border-style: solid;
}

.bagde-pti {
    font-size: 0.6rem;
    background-color: #757575 !important;
    padding: 4px;
}

.bagde-pti-big {
    font-size: 1rem;
    background-color: #757575 !important;
    padding: 4px;
}

.sheet-item-weight {
    color: rgba(0,0,0,0.45);
    font-weight: 600;
}

.sheet-readonly-vote {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    border-radius: 50%;
    border: 2px solid #a7baab;
    font-size: 0.7rem !important;
    font-weight: 700;
}

.sheet-readonly-vote-nd {
    width: 25px;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    font-size: 0.7rem !important;
    font-weight: 700;
}

.sheet-add-question-note {
    position: absolute;
    font-size: 1rem;
    margin-right: -25px;
    color: #8db59c;
    cursor: pointer;
}

.sheet-note-input {
    border: none !important;
    border-bottom: 1px solid #CCC !important;
    height: 1.6rem;
    font-size: 0.7rem;
}

.sheet-question-note-label {
font-size:0.7rem;
font-weight:600;
}

.sheet-question-note-text {
    font-size: 0.7rem;
    font-weight: 400;
    color:#757575;
}
.sheet-action-buttons {
    cursor:pointer;
    text-decoration:none !important;
    font-size:0.8rem;
}
.sheet-action-icons {
font-size:1.4rem;
}