@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

body, html {
    font-family: 'Nunito', sans-serif;
    color: #198527 !important;
    margin: 0;
    width: 100%;
}

.greenBox {
    background-color: rgba(218, 234, 213, 0.85)
}

.newQuestionBox {
    background-color: rgb(188, 212, 181);
    margin: 10px 5px;
    padding: 10px;
}

.textInput {
    width: 80%;
    border-radius: 5px;
    line-height: 2rem;
    padding-left: 10px;
}

.cleanInput {
    border: #FFFFFF00;
}

.numberInput {
    width: 3.5rem;
    margin-left: 10px
}

.headerField {
    padding: 10px;
}


.editingInput {
    width: 100%
}

.search-input-field {
    border-radius: 30px;
    font-size: 0.9rem;
    width: 180px;
}

.addNewElement {
    width: 100%;
}

.deleteFieldIcon {
    margin-left: 10px
}

.stopEditing {
    margin-left: 10px;
    margin-top: 10px;
}

.buttonGroup {
    margin-top: 10px;
}

.switchInput {
    margin-top: 10px
}

.textAreaInput {
    height: 35px;
}

.buttonGroupSubject {
    margin-bottom: 15px;
}


.editSubjectName {
    margin-bottom: 15px
}


.sheetNameInput {
    width: 100%
}

select {
    text-align-last: center
}

.sheetFooter {
    margin-bottom: 50px
}

.centerContent {
    text-align: center;
}

.questionLabel {
    margin-right: 10px;
}

.serviceSelector {
    max-width: 100%;
    width: 100%;
}

.addNewField {
    margin-left: 0;
    padding: 15px;
}

.addNewFiledButtons {
    margin: 10px auto;
}

.switchFix {
    margin-top: -10px;
}

.serviceMultiple__control {
    border-radius: 25px !important;
    /* height: 36px; */
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border: 1px solid #ced4da !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

    .serviceMultiple__control:focus {
        border-radius: 25px !important;
        color: #5a5a5a !important;
        background-color: #fff !important;
        border-color: #d0eae2 !important;
        outline: 0 !important;
        box-shadow: 0 0 0 0.2rem rgba(120,194,173,.25) !important;
    }

.serviceMultiple__multi-value {
    border-radius: 25px !important;
}

.serviceMultiple__multi-value__remove {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
}

.subjectSelector__control {
    border-radius: 4px !important;
    /* height: 36px; */
    font-weight: 400 !important;
    line-height: 1.5 !important;
    border: 1px solid #ced4da !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

    .subjectSelector__control:focus {
        border-radius: 4px !important;
        color: #5a5a5a !important;
        background-color: #fff !important;
        border-color: #d0eae2 !important;
        outline: 0 !important;
        box-shadow: 0 0 0 0.2rem rgba(120,194,173,.25) !important;
    }

.subjectSelector__multi-value {
    border-radius: 4px !important;
}

.subjectSelector__multi-value__remove {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.multi-select {
    --rmsc-main: #d0eae2 !important;
    /* --rmsc-hover: #f1f3f5; */
    --rmsc-selected: #ced4da Important;
    --rmsc-border: #ced4da;
    /* --rmsc-gray: #aaa; */
    --rmsc-bg: #fff;
    --rmsc-p: 5px !important;
    --rmsc-radius: 25px; /* Radius */
    --rmsc-h: 36px; /* Height */
}

.inputWeight {
    width: 55%;
}

.error {
    color: firebrick;
    font-weight: 600;
}


.multiSlider-limit {
    text-align: center;
    padding: 6px 0;
    color: white;
    border-radius: 20px;
}

.addNewMultiSliderLabel {
    margin-bottom: 15px;
    margin-top: 15px;
    text-align: center
}

.sheetListTitle {
    margin-bottom: 25px;
    margin-left: 15px;
}

.sheetListElementRow {
    border-bottom: 1px solid #bad5b9;
}

    .sheetListElementRow:hover {
        background-color: #d0eae23b;
    }

@media only screen and (max-width: 959px) {
    .btn {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h6 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p, label {
        margin-top: 10px;
    }
}

.btn {
    margin-top: 10px;
}

.sheetListHeader {
    border-bottom: 3px solid #72bf89;
    font-family: 'Nunito', sans-serif;
}

.sheetListHeaderText {
    font-family: 'Merriweather', serif;
    font-size: 1.2rem;
    font-weight: 600;
}

.sheetListSheetNameText {
    font-family: 'Merriweather', serif;
    font-size: 0.9rem;
}

.sheetListSheetButtonText {
    font-family: 'Nunito', sans-serif;
}

.ManagerFieldLabels {
    font-size: 0.8rem;
    font-weight: 500;
}

.ManagerRangeBalls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    height: 40px;
    width: 40px;
}

.ManagerRangeDescription {
    flex-grow: 1;
    flex-basis: 100%;
    width: 100%;
}

.ManagerSectionContainer {
    border: 1px dashed #198527;
    border-radius: 10px;
    background-color: #f2fbf3;
    padding-bottom: 14px !important;
}

.ManagerSubSectionContainer {
    border: 1px dashed #198527;
    border-radius: 10px;
    background-color: #feffed;
}

.ManagerSubSubSectionContainer {
    border: 1px dashed #a7e1f1;
    border-radius: 10px;
    background-color: #d7e9ff;
}

.ManagerBtns {
    cursor: pointer;
    text-decoration: none !important;
    border: 1px solid #b9e2ba;
    border-radius: 5px;
    padding: 7px 10px 7px 10px;
}

    .ManagerBtns:hover {
        background-color: #d8e8dd;
    }

.ManagerBtnIcons {
    cursor:pointer;
    font-size: 1.3rem;
}

.ManagerSubjectContainer {
    width: 100%;
}

.ManagerQuestionContainer {
    border-bottom: 1px dashed #198527;
    padding: 15px 10px 10px 15px;
}

.ManagerBadgeWeight {
    min-width: 55px !important;
    width: 55px !important;
    font-size:1rem;
}

.ManagerCreateQuestionContainer {
    background-color:#fffde0;
    border: 1px dashed #e4ea9a;
    padding: 15px 10px 10px 15px;
}
