.valuta-menu-link {
    font-size: 10px;
    font-weight: 800;
}

    .valuta-menu-link:hover {
        text-decoration: none;
    }

    .valuta-menu-link + .valuta-menu-link {
        margin-left: 15px;
    }

.logoConsulcesi {
    width: 300px;
}
