.evaluation-list-cell {
    border-left: 8px solid #cfe0ca;
    background-color: #DCF1D7;
    font-size: 0.7rem;
    height: 41px;
}

.evaluation-list-table-header {
    border-left: 8px solid #198527;
    background-color: white;
    font-size: 0.7rem;
    height: 50px;
    margin-left: 0 !important;
}

.valuta-evaluation-link {
    text-decoration: none !important;
    margin-top: 30px;
    font-weight: 700;
}

.evaluation-user-pic-little {
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.confirmation-icon {
    width: 100px;
    height: 100px;
    display: block;
    border-radius: 50%;
    stroke: #198527;
    margin: 50px auto;
    -webkit-animation: bounceIcon .17s ease-in-out;
    animation: bounceIcon .17s ease-in-out;
    -webkit-animation-delay: .25s;
    animation-delay: .25s;
}

.confirmation-circle {
    stroke-dasharray: 190;
    stroke-dashoffset: 190;
    stroke-width: 9;
    stroke: #9cc6a3;
    fill: none;
    -webkit-animation: drawCircle .2s linear;
    animation: drawCircle .2s linear;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.confirmation-check {
    stroke-width: 7;
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
    -webkit-animation: drawCheckmark .2s cubic-bezier(.89,.09,.91,.67);
    animation: drawCheckmark .2s cubic-bezier(.89,.09,.91,.67) forwards;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}

.ordering-arrow {
    cursor: pointer;
}

    .ordering-arrow:hover {
        color: black;
    }

.previewGeneralContainer {
    width: 100%;
    font-family: 'Merriweather', sans-serif;
    text-align: center;
    background-color: #f1fff3;
    margin-right: 10px;
}

.recent-ev-assistant-name {
    font-size: 0.9rem;
    font-weight: 800;
}

.recent-ev-activity {
    font-family: sans-serif;
    font-size: 0.7rem;
    margin-top: 1px;
}

.previewProfilePic {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-bottom: 15px;
    margin-top: 20px;
}

.previewDetailProfilePic {
    width: 40px !important;
    height: 40px !important;
    margin-left: 10px;
    border-radius: 100px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.profilePicLittle {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
}
.profilePicLittlePDF {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50%;
}

.previewPicBig {
    width: 60px !important;
    height: 60px !important;
}

.previewButtonsGroup {
    margin-bottom: 30px;
}

.recentEvaluationButton {
    border-radius: 2px;
    background-color: white;
    border: 1px solid #EAEAEA;
    font-size: 0.6rem;
    font-family: sans-serif;
    padding: 2px 5px 2px 5px;
    text-align: center;
}

    .recentEvaluationButton + .recentEvaluationButton {
        margin-left: 2px;
    }

    .recentEvaluationButton:hover {
        background-color: #b1dea7;
        color: #05260a;
    }

.RecentPlayerRectangleCircleLittle {
    font-family: sans-serif;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
}

.RecentPlayerRangeCircleLittle {
    font-family: sans-serif;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2rem;
    font-weight: 400;
}

.previewDetailsContainer {
    width: 100%;
    background-color: #DCF1D7;
    margin-bottom: 15px;
}

.evaluationDetailsRole {
    font-weight: 600;
    font-size: 0.75rem;
}

.evaluationDetailsName {
    font-size: 0.65rem;
}

.evaluationDetailsDate {
    font-size: 0.8rem;
}

.evaluationDetailsPercent {
    font-size: 1.1rem;
}

.BrainhubCarousel__arrows {
    background-color: #198527;
}

    .BrainhubCarousel__arrows:hover, .BrainhubCarousel__arrows:hover:enabled {
        background-color: #78c2ad;
    }

.table-striped {
    color: #198627;
    font-size: small;
}

    .table-striped tr {
        background-color: #e2fedb !important;
    }

        .table-striped tr:nth-of-type(odd) {
            background-color: #dcf1d7 !important;
        }

#pageDropDown {
    background-color: #78c2ad;
    border-color: #78c2ad;
    margin-top: 0;
}

    #pageDropDown:focus {
        box-shadow: 0 0 0 0.2rem #dcf1d7
    }

.dropdown-item:hover {
    background-color: #dcf1d7;
}

.page-item.active .page-link {
    background-color: #dcf1d7;
    border-color: #dcf1d7;
}

.page-item > a:hover {
    background-color: #dcf1d7;
    border-color: #dcf1d7;
}

.table td {
    vertical-align: middle;
    padding: 0px 10px;
}

#root > div > div.container-fluid.mt-3 > div.react-bootstrap-table > table > thead > tr {
    background-color: #b1dea7 !important;
}

tr {
    margin-bottom: 3px;
}

.customTableHeader {
    font-weight: 700;
    font-size: 14px;
}

.customTableHeaderGray {
    font-weight: 700;
    font-size: 14px;
    color: gray;
}

.customTableHeader-small {
    font-weight: 600;
    font-size: 0.75rem;
}

.recentEvaluationButtonNoMargin {
    margin: 0;
    margin-right: 5px;
}

.customTableHeader:hover {
    cursor: pointer;
}

.tableFooter {
    margin-top: 15px;
    margin-bottom: 15px;
}

.col-1-extended {
    flex: 0 0 16.6%;
    max-width: 16.6%;
}

.tableMin {
    min-width: 1100px;
}

.fullNameText {
    vertical-align: -webkit-baseline-middle;
    margin-left: 5px;
}

.clickableTableRow {
    cursor: pointer;
}

    .clickableTableRow:hover {
        -webkit-box-shadow: 0px 0px 15px 0px #198200;
        -moz-box-shadow: 0px 0px 15px 0px #198200;
        box-shadow: 0px 0px 15px 0px #198200;
    }


@media only screen and (max-width: 1600px) {
    .btn-sm-no-margin {
        margin-bottom: 0px;
    }

    div.previewButtonContainer > button:nth-child(3) {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 1300px) {
    .responsiveHide {
        display: none;
    }
}

@keyframes drawCheckmark {
    100% {
        opacity: 1;
        stroke-dashoffset: 0;
    }
}

@keyframes bounceIcon {
    0% {
        transform: none;
    }

    50% {
        transform: scale3D(.9, .9, 1);
    }

    100% {
        transform: none;
    }
}


.carousel-item-padding {
    padding: 0 6px 0 6px;
    /* width: 220px !important; */
    /* margin-right: -45px; */
}
/* OVERRIDES CAROUSEL */
.react-multiple-carousel__arrow {
    border-radius: 5px;
    height: 50px;
    background-color: none;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
}

    .react-multiple-carousel__arrow:disabled {
        background-color: none !important;
    }

    .react-multiple-carousel__arrow::before {
        color: #FFF;
        border-color: #198200;
    }

    .react-multiple-carousel__arrow:hover::before {
        border-color: #198527;
    }

    .react-multiple-carousel__arrows:hover, .react-multiple-carousel__arrow:hover:enabled {
        background-color: #198527;
        color: #FFF;
        border: none;
    }

        .react-multiple-carousel__arrow:hover:enabled::before {
            border-color: #198527;
        }

.react-multiple-carousel__arrow--right:before {
    content: ">";
}

.react-multiple-carousel__arrow--left::before {
    content: "<";
}

.loadingListOverlay {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    border: 1px solid #cee1d1;
    border-radius:30px;
    z-index: 1000;
    width: 200px;
    margin-left: calc(50vw - 130px);
    margin-top:20px;
    background-color:rgba(255,255,255,0.8);
    padding:20px 0px 20px 0px;
}

.loadingListOverlayMessage {
    position:relative;
    width:100%;
    text-align:center;
    flex-grow:1;
    margin-top:30px;
    font-size:0.8rem;
    font-weight:600
}

.loadingListOverlaySpinner {
    position:absolute;
    width: 20px;
    height: 20px;
}
.header-sheet-list {
border-bottom:2px solid #198200;
}
.header-sheet-list-row {
    border-bottom: 1px solid #CCC;
}